import { UserData } from '@components/cards/card/shared/UserInfo/UserInfo';

export enum ObjectType {
  USER = 'User',
  BUSINESS = 'Business',
  TICKET = 'Ticket',
  POST = 'Post',
  NEWSLETTER = 'Newsletter',
  DOCUMENT = 'Document',
  DOCUMENT_SPONSORED = 'DocumentSponsored',
  TOOL = 'Tool',
  COMMENT = 'Comment',
  ONLINE_EVENT = 'Event',
  MOMENT = 'Moment',
  BANNER = 'Banner',
}

export enum SingularEventType {
  'Evento difusión' = 1,
  'Evento técnico',
  'Capacitación'
}

export enum PluralEventType {
  'Eventos de difusión' = 1,
  'Eventos técnicos',
  'Capacitaciones'
}


const objectTypes = Object.entries(ObjectType)
  // @ts-ignore
  .map(([enumKey, value]) => ({ [value]: ObjectType[enumKey] }))
  .reduce((acc, act) => ({ ...acc, ...act }));


export function objectTypeFromString(objectType: string): ObjectType {
  return objectTypes[objectType];
}

export enum EventsState {
  FINISHED = 'finished',
  INITIAL = 'initial',
  LIVE = 'live',
}

export enum EventsCategories {
  TECHNICAL = 'technical',
  DIFFUSION = 'diffusion',
  TRAINING = 'training',
}

export enum NotificationType {
  ALL = 'all',
  UNREAD = 'unread',
}

export enum TicketState {
  INITIAL = 1,
  INVESTIGATING,
  WAITING_ANSWER,
  FINALIZED,
  REFUSED,
  APPROVED,
  CANCELED
}

export enum Gender {
  'Masulino' = 1,
  'Femenino',
  'No definido'
}

export declare type AcoObject =
  User
  | UserMin
  | Business
  | Ticket
  | Post
  | Newsletter
  | Document
  | DocumentSponsored
  | OnlineEvent
  | Comment
  | Tool
  | Moment
  | Publicity;

export interface UserMin extends BaseObject {
  self: boolean;
  completeName: string;
  nickname: string;
  verified: boolean;
  img?: string;
}

export interface User extends UserMin {
  name: string;
  lastName: string;
  email?: string;
  description?: string;
  location?: Location;
  userType?: number;
  age?: string;
  dateOfBirth?: Date;
  registrationDate: string;
  occupation?: {
    id: number,
    name: string;
  };
  state?: string;
  stats?: {
    followers: number;
    following: number;
    posts: number;
    ticketsAnswered: number;
    comments: number;
    usefulReceived: number;
    responseTime?: string;
    responsePercentage?: number;
  };
  business?: Business;
  type?: {
    name: string;
  };
  admin: boolean;
  observations: string;
  gender: string;
  thematics?: ThematicExpert[];
  countries?: string[];
  consulting_type?: string;
  paymentAccess: {tickets: boolean, trainings: boolean};
}

export interface Business extends BaseObject {
  name: string;
  nickname?: string;
  img?: string;
  description?: string;
  stats: {
    usersInterested: number;
    following: number;
    ticketsAnswered: number;
    responseTime?: string;
    responsePercentage?: number;
  };
  userInterested: boolean;
  active: boolean;
  verified: boolean;
  state?: string;
  thematics?: ThematicExpert[];
}

export interface TicketEvent {
  id: number;
  user: UserMin;
  text: string;
  type: number;
  date: string;
  attachments?: {
    url: string,
    contentType: string,
    filename: string
  }[];
}

export interface Ticket extends BaseObject, InteractiveObject {
  closeDate: string;
  user: User;
  expert: {
    user?: User;
    business?: Business;
  };
  category: {
    category: string;
    subcategory: string;
  };
  attachments: {
    count: number;
    images: {
      url: string;
      thumbnailUrl: string;
      size: { w: string; h: string; }
    }[];
  };
  state: string;
  answer: string;
  question: string;
  location?: Location;
  events?: TicketEvent[];
  thematics:
    {
      id: number,
      name: string,
    }[],
  rating: number,
}

export interface TicketMin extends BaseObject {
  id: number;
  closeDate: string;
  client: UserData;
  expert: {
    user?: UserData;
    business?: Business;
  };
  attachments: {
    count: number;
    images: {
      url: string;
      thumbnailUrl: string;
      size: { w: string; h: string; }
    }[];
  };
  answer: string;
  question: string;
  location: string;
  thematics:
    {
      id: number,
      name: string,
    }[],
}

export interface Post extends BaseObject, InteractiveObject {
  type: {
    id: number;
    description: string;
    attributes: number;
  };
  content: string;
  minContent?: string;
  frames?: string;
  reference: ReferenceObject;
  private?: {
    success?: string;
    error?: string;
    privacy: string;
  };
  user?: User;
  business?: Business;
  location?: Location;
  attachments: {
    filename: string;
    contentType: string;
    contentLength: number;
    url: string;
    isImage: boolean;
  }[];
  objects: AcoObject[];
}

export interface Newsletter extends BaseObject, InteractiveObject {
  subject: string;
  body: string;
  number: number;
  year: number;
  publicationDate: string;
  objectsStats: {
    documents: number;
    users: number;
    business: number;
    newsletters: number;
    tickets: number;
  };
  objects?: {
    documents: Document[];
    users: UserMin[];
    business: Business[];
    newsletters: Newsletter[];
    tickets: Ticket[];
  };
}

export interface Document extends BaseObject, InteractiveObject {
  authors: string;
  description: string;
  title: string;
  youtubeVideoId?: string;
  attachment?: {
    contentType: string;
    contentLength: string;
    url: string;
    downloads: number;
  };
  keywords: string[];
  type: string;
  source: {
    name: string;
    url?: string;
  };
}

export interface DocumentSponsored extends Document {
  business: Business;
  year: string;
  banner?: {
    url: string;
    link: string;
  };
}

export interface Tool extends BaseObject {
  name: string;
  description: string;
  img: string;
  id: number;
  legacyUrl: string;
}

export interface Moment extends BaseObject {
  event_id: number;
  speaker: string;
  title: string;
  description: string;
  start_time: number;
  finish_time: number;
  imgURL?: string;
}


export interface Comment extends BaseObject {
  message: string;
  messagePlain: string;
  user: UserMin;
  repliesCount: number;
  parentId?: number;
  date: string;
  attachments: {
    order: number;
    url: string;
    thumbnailUrl?: string;
    mediaType?: string;
    filename: string;
  }[];
  reference?: ReferenceObject;
}


export interface OnlineEvent extends BaseObject, InteractiveObject {
  title: string;
  description: string;
  content: string;
  state: number;
  thumbnails: string;
  free: boolean;
  private: boolean;
  live: boolean;
  finished: boolean;
  categoryId: number;
  dateFinish?: string;
  imgProgram?: string;
  moderatorName?: string;
  moderatorId?: number;
  settings: {
    selfRegistration: string;
    amount: string;
    dolarAmount?: string;
  };
  allowed: boolean;
  paid: boolean;
  subscriptions?: number;
}

export interface OnlineEventMin extends BaseObject {
  title: string;
  categoryId: number;
  moderatorId?: number;
  settings: {
    selfRegistration: string;
    amount: string;
    dolarAmount?: string;
  };
  subscriptions?: number;
  subscriptionsByState: {
    notInvoiced: number;
    invoiced: number;
    paid: number;
    canceled: number;
    free: number;
    onAccount: number;
  };
  moneyAvailable: number;
  currency?: string;
}

//COMMONS
export interface BaseObject {
  objectType: ObjectType;
  id?: number;
  url?: string;
  date?: string;
}

export interface ReferenceObject {
  referenceType: ObjectType;
  referenceId: number;
}

export interface InteractiveObject {
  stats: {
    comments: number;
    useful: number;
    shared?: number;
    impressions?: number;
  };
  userStats?: {
    hasUseful: boolean;
  };
  lastComments: Comment[];
  lastUseful: User[];
}

export interface Location {
  country: string;
  lat: number;
  emoji?: string;
  locality?: string;
  administrative_area_level_1?: string;
  administrative_area_level_2?: string;
  address: string;
  lng: number;
}

export interface Country {
  name: string;
  code: string;
}

export interface Activity extends BaseObject {
  activity: string;
  reference?: string;
  relativeDate: string;

  user?: {
    completeName: string;
    urlAvatar: string;
    login: string;
    verified: boolean;
  };

  object?: {
    expert: {
      completeName: string;
      urlAvatar: string;
      verified: boolean;
      type: string;
    },
    client: {
      completeName: string;
      urlAvatar: string;
    }
    id: number;
    answer: string;
  };

  comment?: {
    type: string;
    title: string;
    completeName: string;
    message: string;
    author: string;
    client: string;
    responsible: string;

  };

}

export interface StatsOfAProfile {
  followers: number;
  following?: number;
  ticketsAnswered?: number;
  usefulReceived?: number;
  responseTime?: string;
  responsePercentage?: number;
}

export interface ProfileConfigNotifications extends BaseObject {
  state: boolean;
  type: string;
}

export interface Occupations extends BaseObject {
  name: string;
}

export interface Category {
  category: string;
  subcategory?: string;
  category_id: number;
  subcategory_id?: number;
}

export interface BusinessAdministrator {
  empresa_id: number;
  nombre_completo: string;
  img_perfil: string;
}

export interface Thematic {
  completeName:
    {
      id: number,
      name: string,
    }[],
  tematicas_hijas?: {
    id: number,
    name: string,
  }[],
  id: number,
  name: string,
  subcategoryId?: number,
  childrens?: ThematicExpert[],
  keywords?: [];
}

export interface ThematicExpert {
  name: string;
  id: number;
  keywords?: [];
  completeName: {
    id: number,
    name: string,
  }[];
  subcategoryId?: number;
  childrens?: ThematicExpert[];
  tematicas_hijas?:
    {
      id: number,
      name: string,
    }[],
}


export interface Notifications extends BaseObject {
  read: number;
  title: string;
  completeName: string;
  img: string;
  type: string;
  objectID: number;
}

export interface MonthlyTickets {
  expertMonthlyTickets: number;
}

export interface SearchHighlight extends BaseObject {
  text: string,
  pkg: string,
  img: string,
}

export interface Publicity {
  width: number;
  objectType: string;
  pauta_orden: number;
  dummy: number;
  height: number;
  espacio_html: string;
}

export interface CheckEmail {
  alreadyExists: boolean;
  pending?: boolean;
  accountDeleted?: boolean;
}

export interface ResendEmail {
  success: boolean;
}

export interface CheckLogin {
  valid: boolean;
  suggested: string;
}

export interface UserRegisterLocationData {
  latitude: number;
  longitude: number;
  address: string;
  country: string;
  administrativeLevel1: string;
  administrativeLevel2: string;
  locality: string;
}

export interface UserRegisterData {
  email: string;
  name: string;
  lastName: string;
  gender: string;
  password: string;
  birthDate: string;

  login: string;
  occupationId: number;
  location: UserRegisterLocationData;

  id?: string;
  token?: string;
  picture?: string;
  provider?: number;
  _referrer?: string;
}

export interface ITrackStackRequest {
  eventId: number,
  type: StatType,
  uuid?: string,
}

export interface Coupons {
  success: boolean;
  cupon: string;
  cupones?: {
    'cupon_id': number,
    'clave': string,
    'ref_objeto_id': number,
  }[];
  cantidad: number;
  cupon_id: number;
}

export interface Coupon {
  id: number,
  state: number,
  date: string,
  ref_object_pkg?: string,
  ref_object_id?: number,
  trainingName?: string,
  expiredDate?: string,
  business?: string,
}

export interface Pack {
  name: string,
  id: number,
  price: string,
  duration: string,
  amount: number,
  dolarPrice: string,
}

export interface DetailPayment {
  onlineEventId?: number,
  title?: string
  totalSubs?: number,
  totalAmount: number,
  subsIds?: number[],
  ticketId?: number
  ticketPrice?: number,
  trainingPrice?: number,
  cbu: string,
}

export interface PaymentOrder {
  paymentOrderId: number,
  date: string,
  dateFinish: string,
  state: number,
  amount: number,
  iva: number,
  subtotal: number,
  cbu: string,
  comment?: string,
  details: DetailOrder[]
}

export interface DetailOrder {
  detail: string,
  total: number,
  amount: number,
  objectId: number,
  objectPkg
}

export enum StatType {
  Access = 2,

  //Corresponde a las visualizaciones en los eventos online cada tanto x tiempo.
  Online = 4,

  //Corresponde a la accion en los eventos online de continuar viendo.
  ContinueWatching = 5,

  //Corresponde a la accion en los eventos online de cerrar la ventana.
  CloseEvent = 6
}
